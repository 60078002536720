export const calculateBMIWithCategory = ({ sex, age, weight, height, t }) => {
  if (!sex || !age || !weight || !height) {
    return t('userInformation.bmi.na');
  }

  // Convert height from centimeters to meters
  const heightInMeters = height / 100;

  // Calculate BMI
  const bmi = weight / (heightInMeters * heightInMeters);

  // At the moment, we are not using the recommended BMI range and adjustment
  // Keep code for future use

  // Determine the recommended BMI range and adjustment based on age and sex
  // let normBMI = 0;
  // let adjustment = 0;
  // if (sex.toLowerCase() === 'female') {
  //   if (age >= 19 && age <= 24) {
  //     normBMI = 19.5;
  //     adjustment = -5.5;
  //   } else if (age >= 25 && age <= 34) {
  //     normBMI = 23.2;
  //     adjustment = -1.8;
  //   } else if (age >= 35 && age <= 44) {
  //     normBMI = 23.4;
  //     adjustment = -1.6;
  //   } else if (age >= 45 && age <= 54) {
  //     normBMI = 25.2;
  //     adjustment = +0.2;
  //   } else if (age >= 55) {
  //     normBMI = 27.3;
  //     adjustment = +2.3;
  //   }
  // } else if (sex.toLowerCase() === 'male') {
  //   if (age >= 19 && age <= 24) {
  //     normBMI = 21.4;
  //     adjustment = -3.6;
  //   } else if (age >= 25 && age <= 34) {
  //     normBMI = 21.6;
  //     adjustment = -3.4;
  //   } else if (age >= 35 && age <= 44) {
  //     normBMI = 22.9;
  //     adjustment = -2.1;
  //   } else if (age >= 45 && age <= 54) {
  //     normBMI = 25.8;
  //     adjustment = +0.8;
  //   } else if (age >= 55) {
  //     // eslint-disable-next-line @typescript-eslint/no-unused-vars
  //     normBMI = 26.6;
  //     adjustment = +1.6;
  //   }
  // } else {
  //   return t('userInformation.bmi.na');
  // }

  // Adjusted BMI for categorization
  const adjustedBMI = bmi; 

  // Determine the BMI category
  let categoryKey = '';

  if (adjustedBMI < 16.0) {
    categoryKey = 'severelyUnderweight';
  } else if (adjustedBMI >= 16.0 && adjustedBMI < 16.9) {
    categoryKey = 'moderatelyUnderweight';
  } else if (adjustedBMI >= 17.0 && adjustedBMI < 18.4) {
    categoryKey = 'mildlyUnderweight';
  } else if (adjustedBMI >= 18.5 && adjustedBMI < 24.9) {
    categoryKey = 'normal';
  } else if (adjustedBMI >= 25.0 && adjustedBMI < 29.9) {
    categoryKey = 'overweight';
  } else if (adjustedBMI >= 30.0 && adjustedBMI < 34.9) {
    categoryKey = 'obeseClassI';
  } else if (adjustedBMI >= 35.0 && adjustedBMI < 39.9) {
    categoryKey = 'obeseClassII';
  } else if (adjustedBMI >= 40.0) {
    categoryKey = 'obeseClassIII';
  }

  const category = t(`userInformation.bmi.categories.${categoryKey}`);

  return t('userInformation.bmi.result', {
    adjustedBMI: adjustedBMI.toFixed(1),
    category,
  });
};
