import React from 'react';
import { gql, useMutation } from '@apollo/client';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { TachycardiaEpisode } from 'types/schema.type';
import { TachycardiaChart } from './TachycardiaChart';
import { getTime } from '../utils/getTime';
import { formatSeconds } from '../utils/formatSeconds';

export const UPDATE_TACHYCARDIA_EPISODE = gql`
  mutation UpdateTachycardiaEpisode($id: MongoObjectID!, $isValid: Boolean!) {
    updateTachycardiaEpisode(id: $id, isValid: $isValid) {
      success
      error
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    width: 800,
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  table: {
    width: 700,
  },
  cell: {
    border: 'none',
  },
  chart: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  title: {
    paddingBottom: theme.spacing(1),
  },
  cellSummary: {
    borderBottom: 'none',
    borderTop: '1px solid rgba(224, 224, 224, 1)',
  },
}));

type TachycardiaEpisodesWithChartsProps = {
  tachycardiaEpisodes: TachycardiaEpisode[];
  sequenceStartedAt: number;
  rpeaks: number[];
  edit?: boolean;
};

export function TachycardiaTableWithCharts(props: TachycardiaEpisodesWithChartsProps) {
  const classes = useStyles();
  const { tachycardiaEpisodes, sequenceStartedAt, rpeaks, edit } = props;

  const [updateTachycardiaEpisode] = useMutation(UPDATE_TACHYCARDIA_EPISODE);

  const episodesToShow = edit
    ? tachycardiaEpisodes
    : tachycardiaEpisodes?.filter((ep) => ep.isValid);

  const getTotalDuration = () => {
    const totalSeconds = episodesToShow?.reduce(
      (acc, ep) => acc + Math.round((ep.end - ep.start) / 200),
      0,
    );

    return formatSeconds(totalSeconds || 0);
  };

  const handleUpdateTachycardiaEpisode = async (episode: TachycardiaEpisode) => {
    const { id, isValid } = episode;

    await updateTachycardiaEpisode({
      variables: { id, isValid: !isValid },
      update(cache) {
        cache.modify({
          id: cache.identify({ __typename: 'TachycardiaEpisode', id }),
          fields: {
            isValid(existingIsValid) {
              return !existingIsValid;
            },
          },
        });
      },
    });
  };

  return (
    <Container className={classes.root} component={Paper}>
      <Typography className={classes.title} variant="h6">
        Tachycardia Episodes with Charts
      </Typography>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell>Start Time</TableCell>
            <TableCell>End Time</TableCell>
            <TableCell>Duration</TableCell>
            {edit && <TableCell>Action</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {episodesToShow?.map((ep) => (
            <React.Fragment key={ep.id}>
              <TableRow>
                <TableCell className={classes.cell}>
                  {getTime(sequenceStartedAt, ep.start)}
                </TableCell>
                <TableCell className={classes.cell}>{getTime(sequenceStartedAt, ep.end)}</TableCell>
                <TableCell className={classes.cell}>
                  {formatSeconds(Math.round((ep.end - ep.start) / 200))}
                </TableCell>
                {edit && (
                  <TableCell className={classes.cell}>
                    <IconButton
                      edge="end"
                      aria-label=""
                      onClick={() => handleUpdateTachycardiaEpisode(ep)}
                    >
                      {ep.isValid ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
              {ep.isValid && ep.excerpt?.ch1?.length > 0 && (
                <TableRow>
                  <TableCell className={classes.cell} colSpan={edit ? 4 : 3}>
                    <div className={classes.chart}>
                      <TachycardiaChart
                        recordingStartedAt={sequenceStartedAt}
                        start={ep.start}
                        end={ep.end}
                        rpeaks={rpeaks}
                        signal={ep.excerpt.ch1}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </React.Fragment>
          ))}
          <TableRow>
            <TableCell className={classes.cell} colSpan={2} />
            <TableCell className={classes.cellSummary}>Total Duration</TableCell>
            <TableCell className={classes.cellSummary}>{getTotalDuration()}</TableCell>
            {edit && <TableCell className={classes.cellSummary} />}
          </TableRow>
        </TableBody>
      </Table>
    </Container>
  );
}
